<template>
    <div class="platform-dashboard-pages stats-collapse-product-areas">
        <div class="platform-dashboard-pages__filter" v-if="!filterLoading">
            <BankDashboardProductAreasFilters
                ref="bankDashboardProductAreasFilters"
                @applyFilters="fetchProductAreasData"
            />
        </div>

        <div v-if="dashboardProductAreaContainer" class="platform-dashboard-pages__content">
            <DashboardCommonHeader
                class="mb-5"
                :title="$t('Product Areas')"
                :description="$t('Click on any of the product area from the list for more details on it')"
                :count="dashboardProductAreaContainer.totalScore.count"
                :add-button-text="$t('Manage Product Areas')"
                title-icon="/media/buying/icons/product-areas-icon.svg"
                @onSortChange="handleSortChange"
                @onClickAdd="handleClickAdd"
            />

            <DashboardStatsTable
                :score-title="$t('Company Score - All Product Area(s)')"
                :score-text="$t('Based on all your Product Areas/filter applied')"
                :total-score="dashboardProductAreaContainer.totalScore"
            >
                <template #content>
                    <div class="stats-collapse">
                        <template v-if="dashboardProductAreaContainer.containerData.length">
                            <transition-group name="group-transition">
                                <div :key="item.item.name" v-for="item of dashboardProductAreaContainer.containerData">
                                    <DashboardStatsCollapseTableItem
                                        :containerItem="item"
                                        :filterData="$refs.bankDashboardProductAreasFilters.filterData"
                                        :pageType="BankPageTypes.PRODUCT_AREA"
                                    />
                                </div>
                            </transition-group>
                        </template>
                        <div class="section-card section-card__border" v-else>
                            <DashboardEmptyState
                                icon="/media/buying/icons/speedometer-icon.svg"
                                :title="$t('No Product Area(s) Found')"
                                :text="$t('Your company don\'t have any data for the product area(s) selected. Create a feedback session to start reviewing and get insights into your banks performance.')"
                                :button-text="$t('Add Product Area')"
                                button-link="/business/settings/user-settings/overview"
                                button-icon="/media/buying/icons/plus.svg"
                                class-name="mb-10"
                            />
                        </div>
                    </div>
                </template>
            </DashboardStatsTable>
        </div>

        <div class="h-50-vh" v-if="filterLoading || !dashboardProductAreaContainer">
            <CustomLoader />
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BankPageTypes } from "@/store/enums/bank/BankPageTypes";
import { BankDashboardProductAreasContainer } from "@/store/models/bank/dashboard/BankDashboardProductAreasContainer";
import DashboardCommonHeader from "@/buying-teams/shared-components/dashboard/DashboardCommonHeader";
import DashboardStatsTable from "@/buying-teams/shared-components/dashboard/DashboardStatsTable";
import DashboardStatsCollapseTableItem from "@/buying-teams/shared-components/dashboard/DashboardStatsCollapseTableItem";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import BankDashboardProductAreasFilters from "@/buying-teams/pages/bank/dashboard/components/BankDashboardProductAreasFilters";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";

export default {
    name: "ProductAreas",
    components: {
        BankDashboardProductAreasFilters,
        CustomLoader,
        DashboardEmptyState,
        DashboardStatsCollapseTableItem,
        DashboardStatsTable,
        DashboardCommonHeader
    },
    data() {
        return {
            filterLoading: true,
            dashboardProductAreaContainer: null,
            BankPageTypes,
        }
    },
    computed: {
        bank() {
            return store.getters.bank;
        },
        productAreasDashboardFilter() {
            return store.getters.productAreasDashboardFilter;
        },
    },
    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });

        await store.dispatch('initFiltersData', { type: BankFiltersTypeEnum.PRODUCT_AREAS });
        this.filterLoading = false;

        await this.fetchProductAreasData(this.productAreasDashboardFilter);
    },
    methods: {
        handleSortChange(sorting) {
            this.dashboardProductAreaContainer.sort(sorting);
        },
        handleClickAdd() {
            this.$router.push("/bank/settings/user-settings/overview");
        },
        async fetchProductAreasData(filter = null) {
            try {
                diagLog("filter ", filter);
                this.dashboardProductAreaContainer = null;
                let res = await store.dispatch("fetchBankDashboardDataLevelTwo", {filter, type: BankPageTypes.PRODUCT_AREA});
                this.dashboardProductAreaContainer = new BankDashboardProductAreasContainer(res);
                diagLog("fetchProductAreasData DATA", res);
                diagLog("fetchProductAreasData MODEL", this.dashboardProductAreaContainer);
            } catch (e) {
                console.error(e);
            }
        }
    }
}
</script>

<style lang="scss">
.stats-collapse-product-areas {
    .stats-values {
        margin-right: 50px;
    }
    .stats-table__header--headings {
        margin-right: 66px;
    }
}
</style>
